import { graphql } from "gatsby"
import Release from "@views/Release"

export const pageQuery: any = graphql`
  query ReleaseBySlug(
    $id: String!
    $previousReleaseId: String
    $nextReleaseId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tag
      }
    }
    previous: markdownRemark(id: { eq: $previousReleaseId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextReleaseId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`

export default Release
