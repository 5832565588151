import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import colors from "@constants/colors"
import animation, { cubicBezier } from "@constants/animation"
import breakpoints from "@constants/breakpoints"
import {
  h1Styles,
  h2Styles,
  h3Styles,
  h4Styles,
  h5Styles,
  paragraphStyles,
  smallcapsBold14Styles,
  smallcapsBold13Styles,
} from "@constants/typography"
import Layout from "@components/Layout"
import Helmet from "@components/Helmet"

const Container = styled.div`
  padding: calc(0.5 / 12 * 100vw) calc(1 / 12 * 100vw);
`

const Header = styled.div`
  margin-bottom: 48px;
`

const Tag = styled.div`
  ${smallcapsBold13Styles}
  margin-bottom: 6px;
  color: ${colors.brandOrange};

  @media ${breakpoints.laptop} {
    margin-bottom: 12px;
  }
`

const Title = styled.h2`
  ${h3Styles}
  margin: 0;

  @media ${breakpoints.laptop} {
    ${h2Styles}
    margin: 0;
  }
`

const Date = styled.div`
  ${smallcapsBold13Styles}
  color: ${colors.mediumDarkGray};
`

const Content = styled.div`
  h1 {
    ${h1Styles}
  }

  h2 {
    ${h2Styles}
  }

  h3 {
    ${h3Styles}
  }

  h4 {
    ${h4Styles}
  }

  h5 {
    ${h5Styles}
  }

  h6 {
    ${smallcapsBold14Styles}
  }

  p,
  li {
    ${paragraphStyles}
  }

  a {
    transition: color ${animation.quick} ${cubicBezier};
  }

  a:hover {
    color: ${colors.brandHover};
  }
`

const Nav = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-top: 64px;
`

const Prev = styled.div`
  ${smallcapsBold13Styles}
`

const Next = styled.div`
  ${smallcapsBold13Styles}
`

const Release = ({ data }) => {
  const title = data.site.siteMetadata?.title || `Release Notes`
  const post = data.markdownRemark
  const { previous, next } = data

  const html = React.useMemo(() => {
    return post.html || "<div>Loading</div>"
  }, [post])

  return (
    <Layout>
      <Helmet title={title} />

      <Container>
        <Header>
          {post.frontmatter.tag && <Tag>{post.frontmatter.tag}</Tag>}
          <Title as="h2">{post.frontmatter.title}</Title>
          <Date>{post.frontmatter.date}</Date>
        </Header>
        <Content dangerouslySetInnerHTML={{ __html: html }} />
        <Nav>
          <Prev>
            {previous && (
              <Link to={`/releases${previous.fields.slug}`} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </Prev>
          <Next>
            {next && (
              <Link to={`/releases${next.fields.slug}`} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </Next>
        </Nav>
      </Container>
    </Layout>
  )
}

export default Release
